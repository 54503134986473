import { DropdownSection } from '@leagueplatform/web-common-components';
import { BoxProps } from '@leagueplatform/genesis-commons';
import {
  APP_MANAGED_ROUTES,
  AUTHED_WEBVIEW_URL_NAMES,
  getAuthedWebviewUrl,
  injectCobrowseInitiator,
  LinkHandler,
  UserDropdownProps,
} from '@highmark-web/highmark-common';

export const getUserDropdownConfig = (qualifiers: UserDropdownProps) => {
  const userDropdownConfig: DropdownSection[] = [
    [
      {
        text: 'ACCOUNT_MANAGEMENT',
        to: APP_MANAGED_ROUTES.ACCOUNT_MANAGEMENT,
      },
      ...(qualifiers?.isBackgroundInformationEnabled
        ? [
            {
              text: 'BACKGROUND_INFORMATION',
              to: APP_MANAGED_ROUTES.BACKGROUND_INFORMATION,
            },
          ]
        : []),
      // Conditionally add COB Link to list if insuranceAdministeredByThirdParty is true
      ...(qualifiers?.insuranceAdministeredByThirdParty
        ? [
            qualifiers.isCobSdkEnabled
              ? {
                  text: 'COORDINATION_OF_BENEFITS',
                  to: APP_MANAGED_ROUTES.COORDINATION_OF_BENEFITS,
                }
              : {
                  text: 'COORDINATION_OF_BENEFITS',
                  to: getAuthedWebviewUrl(AUTHED_WEBVIEW_URL_NAMES.COB_V2),
                  onClick() {
                    LinkHandler.handleLink(
                      getAuthedWebviewUrl(AUTHED_WEBVIEW_URL_NAMES.COB_V2),
                    );
                  },
                },
          ]
        : []),
      // Conditionally add CoBrowse link to list if feature flag is enabled
      ...(qualifiers?.isCobrowseEnabled
        ? [
            {
              text: 'START_COBROWSE',
              description: 'START_COBROWSE_DESCRIPTION',
              to: '',
              onClick: () => {
                injectCobrowseInitiator();
              },
            },
          ]
        : []),
    ],
    [
      {
        text: 'ABOUT_BENEFICITY',
        to: '/about-beneficity',
      },
    ],
    [
      {
        to: '/sign-out',
        text: 'LOG_OUT',
        linkProps: {
          color: 'onSurface.text.critical',
        } as BoxProps,
      },
    ],
  ];

  return userDropdownConfig;
};
